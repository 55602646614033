import React, { useState } from 'react'
import { Div, Image, Span } from '../elements'
import { Container, Row, Col } from 'reactstrap'
import ModalVideo from 'react-modal-video'
import 'react-modal-video/scss/modal-video.scss'
import SectionTitle from '../SectionTitle'
import './style.scss'

// images
import portfolio1 from '../../images/book/project/gallery1.png'
import portfolio2 from '../../images/book/project/gallery2.png'
import portfolio3 from '../../images/book/project/gallery3.png'
import portfolio4 from '../../images/book/project/gallery4.png'
import portfolio5 from '../../images/book/project/gallery5.png'
import portfolio6 from '../../images/book/project/gallery6.png'
import portfolio7 from '../../images/book/project/gallery7.png'
import portfolio8 from '../../images/book/project/gallery8.png'
import portfolio9 from '../../images/book/project/gallery9.png'
const portfolios = [
    { image: portfolio1, videoId: 'L61p2uyiMSo' },
    { image: portfolio2, videoId: 'sBws8MSXN7A' },
    { image: portfolio3, videoId: 'L61p2uyiMSo' },
    { image: portfolio4, videoId: 'sBws8MSXN7A' },
    { image: portfolio5, videoId: 'L61p2uyiMSo' },
    { image: portfolio6, videoId: 'sBws8MSXN7A' },
    { image: portfolio7, videoId: 'sBws8MSXN7A' },
    { image: portfolio8, videoId: 'sBws8MSXN7A' },
    { image: portfolio9, videoId: 'sBws8MSXN7A' },
]
const ProfileComponent = ({ className, id }) => {
    const [video, setVideo] = useState(false)
    return (
        <Div className={className} id={id}>
            <Container>
                <Row>
                    <Col lg={{ size: 6, offset: 3 }} xs={12}>
                        <SectionTitle
                            className="sectionTitle"
                            title="Gallery Musettiana"
                        />
                    </Col>
                </Row>
                <Row>
                    {portfolios.map((portfolio, i) => (
                        <Col key={i} lg={3} sm={6} xs={12}>
                            <Div className="projectWrap">
                                <Image src={portfolio.image} alt="" />
                                {/*<Div className="projectContent">
                                    <Span onClick={() => setVideo(true)} >
                                        <Image src={videoIcon} alt="video" />
                                    </Span>
                                    <ModalVideo
                                        channel='youtube'
                                        isOpen={video}
                                        videoId={portfolio.videoId}
                                        onClose={() => setVideo(false)}
                                    />
                                </Div>*/}
                            </Div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </Div>
    )
}
export default ProfileComponent