import React, { Fragment } from 'react'
import HeaderComponent from '../../components/Header'
import HeroArea from '../../components/HeroArea'
import AboutComponent from '../../components/About'
import ImageBlock from '../../components/ImageBlock'
import ProfileComponent from '../../components/Portfolio'
import TestmonialComponent from '../../components/Testmonial'
import VideoComponent from '../../components/Video'
import FooterComponent from '../../components/Footer'

// images
import logo from '../../images/logo-circle.png'
import blockImage1 from '../../images/blockimage/blockSection1.jpg'
import blockImage2 from '../../images/blockimage/banktransfer-background.jpg'

const menus = [
    { name: 'Home', id: 'home' },
    { name: '¿Por qué ayudar?', id: 'help' },
    { name: '¿Cómo ayudar?', id: 'payments' },
    { name: 'Galería', id: 'portfolio' },
]

const BookLanding = () => {
    return (
        <Fragment>
            <HeaderComponent
                className="bookHeaderArea"
                fontawesome="cloud-upload"
                menus={menus}
                logo={logo}
                langName="Italiano"
                langLink="/it"
            />
            <HeroArea
                className="bookHeroArea"
                id="home"
                title="Con tu ayuda podremos evitar el cierre de Musetta."
                sTitle="Tu donación es oportuna y vital. ¡Te necesitamos más que nunca!"
                books={true}
            />
            <ImageBlock className="blockImage" id="help" title="¿Qué recuerdos llevarías de viaje a un nuevo mundo? ¿Qué emociones desearías vivir cuando termine la crisis por el coronavirus?" />
            <AboutComponent className="aboutArea" id="about" lang="es" />
            <ImageBlock className="blockImage" id="sectionHelp" img={blockImage1} title="Como ustedes se imaginarán, Musetta se encuentra en un grave peligro ahora. Sin embargo, nos gustaría seguir abiertos cuando todo termine y tu donación por pequeña que sea nos ayudará a lograr ese objetivo." />
            <TestmonialComponent textArgentina="Dentro de argentina:" textOutArgentina="Desde el exterior:" className="testmonialArea" id="payments" />
            <ImageBlock className="blockImage" id="banktransfer" img={blockImage2} textline1="Banco ICBC" textline2="CAJA DE AHORRO Nº 0867/02101727/49" textline3="CBU: 0150867102000101727493" textline4="ALIAS: MUSE.BUSCA.AYUDA" textline5="Transferencia bancaria:" />
            <VideoComponent className="videoArea" id="thanks" textThanks="¡Gracias!" text="Publicaremos el año próximo unas memorias musetteanas donde serán especialmente mencionados/as en los agradecimientos. Publicaremos en nuestras redes de manera permanente sus nombres o el de sus empresas y organizaciones agradeciéndoles su apoyo.
Serán invitadas/os con una copa del mejor espumante para brindar cuando volvamos a abrir.
Organizaremos una fiesta de fin de año de la que serán absolutas/os protagonistas.
Donantes anónimos recibirán nuestros mejores pensamientos. ¡Sinceramente gracias gracias gracias!" />
            <ProfileComponent className="portfolioArea" id="portfolio" />
            <FooterComponent className="footerArea bookFooterArea" />
        </Fragment>
    )
}
export default BookLanding