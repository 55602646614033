import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { Div, H3, P, Image, Span } from '../elements'
import SectionTitle from '../SectionTitle'
import './style.scss'

// images
import about1 from '../../images/about/first-about-block.jpg'
import about2 from '../../images/about/second-about-block.png'

const abouts = [
    {
        image: about1,
        //title: 'Established Companies',
        it: "Quanti tra voi saranno passati, condotti dal fato o dal caso, per le strade che si incrociano formando l'angolo di calle Billnghurst y Tucumán e, fermati solo per un caffè o un bicchiere di vino, si sono d'improvviso sentiti coinvolti dalle conversazioni  di altri commensali.",
        es: 'Ubicada en el barrio de Almagro de la Ciudad de Buenos Aires, Musetta es un espacio donde celebramos la amistad, el buen hablar, la música embriagadora... Un lugar donde cada persona puede sentirse en casa, no importa de dónde venga o cuán lejos esté de su familia o de su país. Quienes hacemos Musetta desearíamos continuar esta tradición...',
    },
    {
        image: about2,
        //title: 'Established Companies',
        it: "Attimi musettiani che hanno generato nuovi amici e nuovi amori durati anche solo per pochi istanti, il tempo forse d'un pomeriggio o una nottata, ma che vi hanno fatto sentire la magia della bellezza della vita e l'ebrezza, non solo alcolica, di un viaggio che sta per cominciare…",
        es: 'Enrique Vila Matas, autor español escribió, quizás exagerando un poco en su libro Mac y su contratiempo: "... he pensado en los turistas y también en todos esos amigos que viajan para ver aquello que tanto soñaron ver: la torre de Pisa, el Palacio de Cristal en Madrid, las Grandes Pirámides en las afueras de El Cairo, las siete colinas de Roma, la Gioconda en Paris, la silla del bar Melitón de Cadaqués en la que Duchamp se sentaba a jugar el ajedrez, el Musetta Caffé [...] en Buenos Aires... ".',
        className: 'order-last',
        left: 'aboutContent2'
    },
]
const AboutComponent = ({ className, id, lang }) => {
    return (
        <Div className={className} id={id}>
            <Container>
                {abouts.map((about, i) => (
                    <Div key={i} className="AboutWrap">
                        <Row noGutters>
                            <Col className={about.className && about.className} lg={6} md={6}>
                                <Div className="aboutImg">
                                    <Image src={about.image} alt="" />
                                </Div>
                            </Col>
                            <Col lg={6} md={6}>
                                <Div className={!about.left ? 'aboutContent' : `aboutContent ${about.left}`}>
                                    {/*<H3>{about.title}</H3>*/}
                                    <P>{about[lang]}</P>
                                </Div>
                            </Col>
                        </Row>
                    </Div>
                ))}

            </Container>
        </Div>
    )
}
export default AboutComponent