import React, { Fragment } from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom'
import BookLanding from '../BookLanding'
import BookLandingIt from '../BookLandingIt'
import NotFoundPage from '../404'
import './style.scss';

const App = () => {
    return (
        <Fragment>
            <Router>
                <Switch>
                    <Route path="/" exact component={BookLanding} />
                    <Route path="/it" component={BookLandingIt} />
                    <Route component={NotFoundPage} /> 
                </Switch>
            </Router>
        </Fragment>
    );
}

export default App;
