import React from 'react'
import { Div, P } from '../elements'
import { Container, Row, Col } from 'reactstrap'
import SectionTitle from '../SectionTitle'
import 'react-accessible-accordion/dist/fancy-example.css';
import './style.scss'

import defaultImage from '../../images/blockimage/background-help-musetta.jpg'

const ImageBlock = ({ className, id, title, img, textline1, textline2, textline3, textline4, textline5 }) => {
    const image = img || defaultImage
    return (
        <Div className={className} id={id} style={{backgroundImage: `url(${image})`}}>
            <Container>
                <Row>
                    {title ? 
                        <Col lg={{ size: 6, offset: 3 }}>
                            <SectionTitle
                                className="sectionTitle sectionTitleStyleTwo"
                                title={title}
                            />
                        </Col>
                    : '' }
                    {textline5 ? 
                        <Col className="banktransferline" lg={{ size: 6, offset: 3 }}>
                            <SectionTitle
                                className="sectionTitle sectionTitleStyleTwo"
                                title={textline5}
                            />
                        </Col>
                    : '' }
                    {textline1 ? 
                        <Col className="banktransferline" lg={{ size: 6, offset: 3 }}>
                            <SectionTitle
                                className="sectionTitle sectionTitleStyleTwo"
                                title={textline1}
                            />
                        </Col>
                    : '' }
                    {textline2 ? 
                        <Col className="banktransferline" lg={{ size: 6, offset: 3 }}>
                            <SectionTitle
                                className="sectionTitle sectionTitleStyleTwo"
                                title={textline2}
                            />
                        </Col>
                    : '' }
                    {textline3 ? 
                        <Col className="banktransferline" lg={{ size: 6, offset: 3 }}>
                            <SectionTitle
                                className="sectionTitle sectionTitleStyleTwo"
                                title={textline3}
                            />
                        </Col>
                    : '' }
                    {textline4 ? 
                        <Col className="banktransferline" lg={{ size: 6, offset: 3 }}>
                            <SectionTitle
                                className="sectionTitle sectionTitleStyleTwo"
                                title={textline4}
                            />
                        </Col>
                    : '' }
                </Row>
            </Container>
        </Div>
    )
}
export default ImageBlock