import React from 'react'
import { Div, P, Span, H3, Image } from '../elements'
import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap'
import SectionTitle from '../SectionTitle'
import './style.scss'

import mercadoLogo from '../../images/mercadopago-logo.png'
import paypalLogo from '../../images/paypal.png'

const payments = [
    {
        price: '$ 100,00',
        link: 'https://www.mercadopago.com.ar/checkout/v1/redirect?pref_id=44955751-3f4f56d4-78eb-42e8-8cd4-3806e0e8fecf'
    },
    {
        price: '$ 250,00',
        'link': 'https://www.mercadopago.com.ar/checkout/v1/redirect?pref_id=44955751-86a23992-0b08-4fb9-985b-95891cd3fbc3'
    },
    {
        price: '$ 500,00',
        'link': 'https://www.mercadopago.com.ar/checkout/v1/redirect?pref_id=44955751-94e89303-8638-4fd4-87e5-2c002f77692c'
    },
    {
        price: '$ 1000,00',
        'link': 'https://www.mercadopago.com.ar/checkout/v1/redirect?pref_id=44955751-94e89303-8638-4fd4-87e5-2c002f77692c'
    },
    {
        price: '$ 2500,00',
        'link': 'https://www.mercadopago.com.ar/checkout/v1/redirect?pref_id=44955751-55516bc3-393d-414a-87cb-d753ff034944'
    },
    {
        price: '$ 5000,00',
        'link': 'https://www.mercadopago.com.ar/checkout/v1/redirect?pref_id=44955751-f3c5f152-6c39-4f8a-a18b-23c195f0b773'
    },
    {
        price: '$ 10000,00',
        'link': 'https://www.mercadopago.com.ar/checkout/v1/redirect?pref_id=44955751-553b1bfa-fa11-4465-b708-d7c4f19e5015'
    },
]

const TestmonialComponent = ({ className,id, textArgentina, textOutArgentina }) => {
    return (
        <Div className={className} id={id}>
            <Container>
                <Row>

                    <Col lg={{ size: 6, offset: 3 }}>
                        <SectionTitle
                            className="sectionTitle"
                            title={textArgentina}
                        />
                    </Col>
                    {payments ? 
                        <Col lg={12} className="methodLogo">
                            <Image src={mercadoLogo} />
                        </Col>
                    : '' }
                    <Row className="mercadoPagoRow">
                        {payments.map((payment, i) => (
                            <Col key={i} lg={3}>
                            <NavLink target="_BLANK" href={payment.link}>
                                <Div className="testWrap">
                                    <H3>BOTÓN DE PAGO</H3>
                                        <Span>{payment.price}</Span>
                                </Div>
                            </NavLink>
                            </Col>
                        ))}
                    </Row>
                    
                    <Col lg={{ size: 6, offset: 3 }}>
                        <SectionTitle
                            className="sectionTitle"
                            title={textOutArgentina}
                        />
                    </Col>
                    <Col lg={12} className="methodLogo">
                        <Image src={paypalLogo} />
                    </Col>
                    
                    <Col lg={12} className="paypalSection">
                        <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                            <input type="hidden" name="cmd" value="_s-xclick" />
                            <input type="hidden" name="hosted_button_id" value="E97RUYG5HPMV4" />
                            <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                            <img alt="" border="0" src="https://www.paypal.com/en_AR/i/scr/pixel.gif" width="1" height="1" />
                        </form>
                    </Col>
                    

                </Row>
            </Container>
        </Div>
    )
}
export default TestmonialComponent