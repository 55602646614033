import React, { Fragment } from 'react'
import HeaderComponent from '../../components/Header'
import HeroArea from '../../components/HeroArea'
import AboutComponent from '../../components/About'
import ImageBlock from '../../components/ImageBlock'
import ProfileComponent from '../../components/Portfolio'
import TestmonialComponent from '../../components/Testmonial'
import VideoComponent from '../../components/Video'
import FooterComponent from '../../components/Footer'

// images
import logo from '../../images/logo-circle.png'
import blockImage1 from '../../images/blockimage/blockSection1.jpg'
import blockImage2 from '../../images/blockimage/banktransfer-background.jpg'

const menus = [
    { name: 'Home', id: 'home' },
    { name: 'Perchè aiutare?', id: 'help' },
    { name: 'Come aiutare?', id: 'about' },
    { name: 'Gallery', id: 'portfolio' },
]

const BookLandingIt = () => {
    return (
        <Fragment>
            <HeaderComponent
                className="bookHeaderArea"
                fontawesome="cloud-upload"
                menus={menus}
                logo={logo}
                langName="Spagnolo"
                langLink="/"
            />
            <HeroArea
                className="bookHeroArea"
                id="home"
                title="Amici! Il vostro aiuto è fondamentale per evitare la chiusura di musetta. La vostra donazione è opportuna e vitale. La nostra gratitudine eterna. Facciamo l’impossibile!"
                books={true}
            />
            <ImageBlock className="blockImage" id="help" title="La parola che più si presta a definire il Musetta Caffè di Buenos Aires è quella dell’amicizia." />
            <AboutComponent className="aboutArea" id="about" lang="it" />
            <ImageBlock className="blockImage" id="sectionHelp" img={blockImage1} title="Adesso però Musetta è in pericolo, come molti altri luoghi per l'emergenza del COVID 19, e vi chiede un aiuto sincero per far si che possa continuare ad esistere ed a tenere ancora aperte le  porte agli amici, ai bohemien, ai viaggiatori ed ai flanuers…." />
            <TestmonialComponent textArgentina="In Argentina" textOutArgentina="Per l'estero" className="testmonialArea" id="payments" />
            <ImageBlock className="blockImage" id="banktransfer" img={blockImage2} textline1="Banco ICBC" textline2="CAJA DE AHORRO Nº 0867/02101727/49" textline3="CBU: 0150867102000101727493" textline4="ALIAS: MUSE.BUSCA.AYUDA" textline5="Bonifico Bancario:" />
            <VideoComponent className="videoArea" id="thanks" textThanks="Grazie!" text="L'anno prossimo pubblicheremo delle momorie musettiane dove sarete specialmente menzionati nei ringraziamenti. Pubblicheremo nelle nostre reti in maniera permanente i vostri nomi o quello delle vostre imprese e organizzazioni ringraziandovi per l'appoggio. 
Vi offriremo il nostro miglio spumante per brindare insieme a noi alla riapertura. 
Organizzeremo una festa di fine anno della quale sarete assoluti protagonisti.
I donatori anonimi riceveranno i nostri pensieri piú profondi e belli. ¡Sinceramente grazie grazie grazie!" />
            <ProfileComponent className="portfolioArea" id="portfolio" />
            <FooterComponent className="footerArea bookFooterArea" />
        </Fragment>
    )
}
export default BookLandingIt